.breadcrumb {
  width: 100%;
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 36px;
}
.breadcrumb_item {
  text-decoration: none;
  color: #777780;
  font-size: 12px;
  cursor: pointer;
}
