.step_bar {
  height: 8px;
  width: 17%;
}
.m_stepper {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  height: 40px;
  padding-inline: 16px;
  gap: 4px;
  margin-bottom: 10px;
  display: none;
  background-color: #fff;
  justify-content: space-between;
}
.m_stepper > span {
  font-size: 12px;
  font-weight: 400;
  color: #777780;
  margin-right: 4px;
  min-width: 58px;
}
@media (max-width: 768px) {
  .m_stepper {
    display: flex;
  }
}
