.step_number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step {
  display: flex;
  gap: 48px;
  align-items: end;
}
.stepper {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  height: 100px;
  padding-inline: 48px;
  gap: 48px;
  margin-bottom: 10px;
}
.step_info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}
.step_info > b {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  background-color: #f6f6f6;
  color: #808080;
}
.step_info > p {
  font-size: 16px;
  font-weight: 400;
}
.stepper > .active > .step_info > b {
  background-color: #56bdc5;
  color: #fff;
}
.step.active > .step_info > b {
  background-color: #56bdc5;
}

.step.completed > .step_info > b {
  background-color: #f0f0f0;
  color: #56bdc5;
}

@media (max-width: 1270px) {
  .stepper {
    gap: 24px;
    justify-content: space-between;
  }
  .step {
    gap: 24px;
  }
}
@media (max-width: 1071px) {
  .arrow {
    display: none;
  }
  .stepper {
    padding-inline: 24px;
  }
}
@media (max-width: 768px) {
  .stepper {
    display: none;
  }
}
