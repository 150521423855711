.ant-collapse-item {
  border-radius: 0 !important;
  background-color: #fff;
  /* background-color: #f1f1f6; */
}

.ant-collapse-header {
  border-radius: 0 !important;
  font-size: 16px !important;
  height: 104px;
  display: flex !important;
  align-items: center !important;
  font-weight: 400;
  background-color: #fff;
  border: none !important;
  outline: none !important;
}
.ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ant-collapse-content-box > p {
  font-size: 16px;
  font-weight: 400;
  color: #000000b2;
}
.ant-collapse-content-box > p > img {
  margin-left: 3px;
  cursor: pointer;
}
.accordeon {
  width: 100%;
  display: flex;
  gap: 10px;
}
.accordeon_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 96px;
  padding-block-end: 96px;
}
.accordeon_wrapper > b {
  font-size: 36px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .accordeon {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-color: transparent;
  }
  .accordeon > :first-child > :last-child {
    border-bottom: 1px solid #d9d9d9;
  }
  .accordeon .ant-collapse {
    width: 100% !important;
    border: none;
  }
}
@media (max-width: 375px) {
  .ant-collapse-header {
    height: 64px;
    font-size: 16px;
  }
  .accordeon_wrapper > b {
    font-size: 18px;
  }
  .accordeon_wrapper {
    gap: 16px;
    margin-top: 32px;
  }
  .ant-collapse-content-box {
    gap: 0;
  }
  .ant-collapse-content-box > p {
    font-size: 16px;
  }
  .accordeon_wrapper > b {
    font-size: 18px;
    font-weight: 400;
  }
}
