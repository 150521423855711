.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.tags > a {
  /* padding: 8px; */
  background-color: #f9f9fb;
  color: #00000095;
}
.tags > a:hover {
  border: 1px solid #56bdc5;
  margin: 0;
}
.container_form {
  width: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: blue;
  /* max-height: 44vw; */
  overflow: scroll;
}

.form_title {
  width: 100%;
  height: auto;
  display: flex;
  padding-block: 40px;
  padding-inline: 40px;
  align-items: center;
  font-size: 36px;
  font-weight: 400;
  border-bottom: 1px solid #0000001a;
}
.form_body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.form_body > p {
  font-size: 16px;
  font-weight: 600;
}

.checkbox > img {
  width: 150px;
  height: 150px;
}
.radio_label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.radio_label > p {
  font-size: 16px;
  font-weight: 400;
}
.radio_circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #0000001a;
}
.radio_color_circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.circleActive {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #56bdc5;
}
.circleColorActive {
  outline: 2px solid #56bdc5 !important;
  border: 2px solid #fff !important;
}

.form_button > button {
  width: 100%;
  height: 53px;
  background-color: #56bdc5;
  color: #fff;
  border: none;
  border-radius: 70px;
  font-size: 18px;
  font-weight: 400;
}
@media (max-width: 1170px) {
  .container_form {
    width: 70% !important;
  }
}
@media (max-width: 1028px) {
  .container_form {
    width: 70% !important;
  }
}
@media (max-width: 964px) {
  .container_form {
    width: 100% !important;
    /* max-height: none; */
  }
  .checkbox {
    width: 47% !important;
    height: 30vw;
  }
  .checkbox > img {
    width: 70% !important;
    height: 90% !important;
  }
  .checkbox_form {
    justify-content: space-between !important;
  }
  .form_title {
    height: 106px !important;
  }
}
@media (max-width: 600px) {
  .checkbox_form {
    flex-wrap: wrap !important;
  }

  .checkbox_form > {
    flex: 0 0 50%;
    max-width: 45%;
  }
}

@media (max-width: 375px) {
  .form_title > p {
    font-size: 18px !important;
    line-height: 24px;
  }
  .form_title {
    height: 71px !important;
  }
  .checkbox {
    font-size: 10px;
  }
  .form_title {
    padding-inline: 16px !important;
  }
  .form_body {
    padding-inline: 16px !important;
  }
  .form_button {
    padding-inline: 16px !important;
  }
}
