@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Exo 2";
}
body {
  display: flex;
  justify-content: center;
}
html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}
div::-webkit-scrollbar {
  display: none;
}
form::-webkit-scrollbar {
  display: none;
}
.App {
  max-width: 1920px;
  width: 100vw;
  background-color: #f9f9fb;
  padding-inline: 66px;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.wrapper {
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: scroll;
  justify-content: end;
}
@media (max-width: 1511px) {
  .container {
    justify-content: center;
  }
}
.main_card {
  background-color: #fff;
  padding-inline: 40px;
  width: 50%;
  height: 44vw;
  display: flex;
  flex-direction: column;
  gap: 21px;
  align-items: center;
  justify-content: end;
  padding-block: 40px;
}
.main_card > img {
  max-width: 371px;
  width: 70%;
}
.main_card > p {
  font-size: 16px;
  font-weight: 400;
  color: #777780;
  width: 100%;
  text-align: left;
}
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.tags_r {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.tags > a {
  height: 30px;
  background-color: #f9f9fb;
  color: #00000095;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  margin: 0 1px;
}
.tags_r > a {
  height: 30px;
  background-color: #f9f9fb;
  color: #00000095;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  margin: 0 1px;
}
.container_form {
  width: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.form_title {
  width: 100%;
  height: 122px;
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: 400;
  border-bottom: 1px solid #0000001a;
}
.form_button {
  width: 100%;
  display: flex;
  align-items: center;
  padding-inline: 40px;
  height: 93px;
  border-top: 1px solid #0000001a;
  padding-block: 20px;
}
.form_body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding-inline: 40px;
}
.form_body > p {
  font-size: 16px;
  font-weight: 600;
}
.checkbox_form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.checkbox_form_img {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.checkbox_form_d {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.checkbox {
  width: calc(50% - 5px) !important;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
  padding-block: 10px;
  gap: 10px;
  align-items: center;
  border: 1px solid #0000001a;
}
.checkbox_img {
  width: calc(50% - 5px) !important;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
  padding-block: 10px;
  gap: 10px;
  align-items: center;
  border: 1px solid #0000001a;
  transition: 0.3s;
}
.checkbox > img {
  width: 150px;
  height: 150px;
}
.radio_label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.radio_label > p {
  font-size: 16px;
  font-weight: 400;
}
.radio_label > input {
  width: 24px;
  height: 24px;
}
.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.container_form > button {
  width: 100%;
  height: 53px;
  background-color: #56bdc5;
  color: #fff;
  border: none;
  border-radius: 70px;
  font-size: 18px;
  font-weight: 400;
}
.column_text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.column_text > p:first-child {
  font-size: 16px;
  font-weight: 400;
}
.column_text > p:last-child {
  font-size: 16px;
  font-weight: 400;
  color: #777780;
}
.radio_circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #0000001a;
}
.radio_color_circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.circleActive {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #56bdc5;
}
.circleColorActive {
  outline: 2px solid #56bdc5 !important;
  border: 2px solid #fff !important;
}
.checkbox {
  width: 212px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
  padding-block: 10px;
  gap: 10px;
  align-items: center;
  border: 1px solid #0000001a;
  transition: 0.3s;
}
.checkbox:hover {
  border: 1px solid #56bdc5;
}
.checkbox_img:hover {
  border: 1px solid #56bdc5;
}
.checkbox_form_d {
  width: 100% !important;
  flex-direction: column !important;
}
.checkbox_form_d > .checkbox {
  width: 100% !important;
}

@media (max-width: 964px) {
  .main_card {
    display: none;
  }
}

@media (max-width: 744px) {
  .App {
    padding-inline: 16px;
  }
  .form_body {
    padding-inline: 16px;
  }
}
@media (max-width: 440px) {
  .checkbox_form {
    flex-direction: column;
    width: 100%;
  }
  .checkbox {
    width: 100% !important;
  }
}
@media (max-width: 400px) {
  .checkbox_img > img {
    max-width: 86px;
    max-height: 86px;
    object-fit: contain;
  }
  .checkbox_img > .radio_label > .radio_circle {
    display: none;
  }
}
@media (max-width: 375px) {
  .form_body > p {
    font-size: 14px;
  }
  .form_button > button {
    font-size: 16px;
    height: 43px;
  }
  .checkbox_img > img{
    max-width: 86px;
    max-height: 86px;
    object-fit: contain;
  }
}
