.stats {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.stats > .space_text {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #777780;
}
.stats > .space_text > p {
  width: 196px;
}
.price {
  background-color: #f9f9fb;
  /* min-height: 264px; */
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: auto;
  margin: 0 40px;
  padding-inline: 40px;
  padding-block: 40px;
}
.stats > .space_text {
  padding-inline: 40px;
}
.price > p {
  font-size: 24px;
  font-weight: 400;
  color: #000;
}
.price > div {
  display: flex;
  align-items: end;
  gap: 16px;
  height: 53px;
}
.price > div > p {
  font-size: 30px;
  font-weight: 400;
  color: #56bdc5;
}
.price > div > b {
  font-size: 44px;
  font-weight: 400;
  color: #000000;
}
.price > span {
  color: #777780;
  font-size: 16px;
  font-weight: 400;
}
.alerts {
  padding-block: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #777780;
  border-block: 1px solid #0000001a;
  padding-inline: 40px;
}
.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 40px;
  padding-block-end: 40px;
}
.actions > :first-child {
  height: 53px;
  width: 49%;
  border-radius: 70px;
  border: 2px solid #56bdc5;
  color: #56bdc5;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  outline: none;
}
.actions > :last-child {
  height: 53px;
  width: 49%;
  border-radius: 70px;
  color: #fff;
  font-size: 14px;
  background-color: #56bdc5;
  font-weight: 400;
  border: none;
  outline: none;
}
@media (max-width: 744px) {
  .stats > .space_text,
  .alerts,
  .actions {
    padding-inline: 32px;
  }
  .price {
    margin: 0 32px;
  }
}
@media (max-width: 550px) {
  .price {
    gap: 16px;
    height: auto;
    padding-block: 16px;
    padding-inline: 16px;
    margin: 0 16px;
  }
  .price > p {
    font-size: 18px;
  }
  .price > div > b,
  .price > div > p {
    font-size: 24px;
  }
}
@media (max-width: 375px) {
  .actions {
    flex-direction: column;
    gap: 16px;
  }
  .actions {
    flex-direction: column-reverse;
  }
  .price > p {
    font-size: 18px;
  }
  .price > b {
    font-size: 24px;
  }
  .stats > .space_text {
    flex-direction: column;
    gap: 16px;
  }
  .actions > button {
    width: 100% !important;
  }
  .stats > .space_text,
  .alerts,
  .actions {
    padding-inline: 16px;
  }
}
@media (max-width: 320px) {
  .price > div > b {
    font-size: 33px;
  }
}
