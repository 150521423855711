.title_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 32px;
}
.title_text {
  font-size: 44px;
  font-weight: 400;
  line-height: 53px;
}
.subtitle_text {
  font-size: 16px;
  font-weight: 400;
  color: #00000095;
  line-height: 20px;
  font-family: "Exo 2";
}
.home {
  display: flex;
  flex-direction: column;
  padding-block-start: 120px;
  padding-block-end: 189px;
  gap: 120px;
  align-items: center;
}

.card {
  width: 268px;
  height: 267px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
  font-size: 16px;
  font-weight: 400;
  color: #00000095;
  flex-direction: column;
  background-color: #fff;
  cursor: pointer;
  transition: 0.4s;
}
.card:hover {
  transform: scale(1.03);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.card > img {
  max-width: 150px;
  max-height: 150px;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

@media (max-width: 744px) {
  .card {
    width: 230px;
    height: 230px;
  }
  .card > img {
    max-width: 120px;
    max-height: 120px;
  }
  .home {
    padding-block-start: 48px;
    padding-block-end: 48px;
    gap: 48px;
  }
}
@media (max-width: 501px) {
  .card > img {
    max-width: 50%;
    max-height: 50%;
  }
  .card {
    width: 40vw;
    height: 42vw;
  }
}
@media (max-width: 375px) {
  .title_container {
    gap: 16px;
  }
  .title_text {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
  }


}
@media (max-width: 328px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .card {
    width: 80%;
    height: 85vw;
  }
}
